import axios from "axios";

const basePath =
  process.env.NODE_ENV === "development"
    ? "http://0.0.0.0:8638/api/"
    : "/api/";


export const myAxios = axios.create({
    timeout: 3000,
    // headers: { "X-Custom-Header": "foobar" },
    });
      


// GET get images
export function getImage(path) {
    const response = myAxios
    .get(`${basePath}images/?path=${path}`)
    .then((response) => response.data)
    .catch((data) => console.log("err_data: ", data))
    return response;

  }

// GET get files
export function getFiles(path) {
    const response = myAxios
    .get(`${basePath}assets/?path=${path}`)
    .then((response) => response.data)
    .catch((data) => console.log("err_data: ", data))
    return response;

  }

  export function addFile(formData) {
    const response = myAxios
    .post(`${basePath}add/`, formData, {
        headers: {
        'Content-Type': 'multipart/form-data',
      },
    })
    .then((response) => response.data)
    .catch((data) => console.log("err_data: ", data))
    return response;

  }