import * as React from 'react';
import PropTypes from 'prop-types';
import { styled } from '@mui/material/styles';
import AppBar from '@mui/material/AppBar';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Link from '@mui/material/Link';
import CircularProgress from '@mui/material/CircularProgress';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import FolderIcon from '@mui/icons-material/Folder';

import {getImage, addFile} from '../src/server'



const VisuallyHiddenInput = styled('input')({
  clip: 'rect(0 0 0 0)',
  clipPath: 'inset(50%)',
  height: 1,
  overflow: 'hidden',
  position: 'absolute',
  bottom: 0,
  left: 0,
  whiteSpace: 'nowrap',
  width: 1,
});

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}


export default function Images() {

  const [upload, setUpload] = React.useState(true);
  const [tab, setTab] =  React.useState(0);
  const [files, setFiles] = React.useState([]);
  const [folders, setFolders] = React.useState([]);
  const [path, setPath] = React.useState("");

  
  const handleChange = (event, newValue) => {
    setTab(newValue);
  };

  React.useEffect(() => {
    setUpload(true)
    if (tab === 0){
      getImage(path)
      .then((data) => {
        setFiles(data['files'])
        setFolders(data['folders'])
        setUpload(false)

      })
      .catch((err_data) => {
        console.log("no data", err_data);
      });
    }
  }, [path, tab])



  const openInNewTab = (url) => {
    window.open(url, "_blank", "noreferrer");
  };

  const expandFolder = (folder) => {
    setUpload(true)
    if (path !== ""){
      setPath(path + folder + "/")
    } else {
      setPath(folder + "/")
    }
  };

  const defaultFolder = () => {
    setUpload(true)
    setPath("")
  };

  const handleFileUpload = (event) => {
    const file = event.target.files[0];
    const formData = new FormData();
    formData.append('upload', file);
    formData.append('path', path);
    addFile(formData)
    .then((resp) => console.log(resp))
    .catch((err_data) => {
      console.log("no data", err_data);
    });
  }


  return (
    <Paper sx={{ maxWidth: 936, margin: 'auto', overflow: 'hidden' }}>
      <AppBar component="div" position="static" elevation={0} sx={{ zIndex: 0, marginBottom: 3 }}>
        <Tabs value={tab} onChange={handleChange} textColor="inherit">
          <Tab label="Images" {...a11yProps(0)} />
          <Tab label="Add new" {...a11yProps(1)} />
        </Tabs>
      </AppBar>


      <Breadcrumbs aria-label="breadcrumb" sx={{marginLeft: 2}}>
        <Link underline="hover" color="inherit" onClick={()=>defaultFolder()}>
          images
        </Link>
        {path.split("/").map((p) => (
          <Link key={p} underline="hover" color="inherit">
            {p}
          </Link>
        ))}
      </Breadcrumbs>

      <CustomTabPanel value={tab} index={0}>
        {upload && 
          <CircularProgress />
        }
        {!upload && 
          <Box>
            <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
              {folders.map((itemFO) => (
                <Grid item xs={2} sx={{border: 0}} onClick={() => {expandFolder(itemFO)}}>
                  
                  <Item><FolderIcon fontSize='large' /></Item>
                  <ItemName>{itemFO}</ItemName>
                  
                </Grid>
              ))}

              {files.map((itemFL) => (
                <Grid item xs={2} sx={{border: 0}} onClick={() => {openInNewTab(`/image/${path}${itemFL}`)}}>
                  
                  <Item>
                    <img 
                    height={30} 
                    width={30} 
                    src={`/image/${path}${itemFL}`}
                    alt={`${path}${itemFL}`}
                    />
                  </Item>
                  <ItemName>{itemFL.slice(0, 10)}{itemFL.length > 10 && "..."}</ItemName>
                  
                </Grid>
              ))}
            </Grid>
          </Box>
        }

      </CustomTabPanel>
      <CustomTabPanel value={tab} index={1}>
        <Button component="label" variant="contained" startIcon={<CloudUploadIcon />}>
          Upload file
          <VisuallyHiddenInput type="file" onChange={handleFileUpload} />
        </Button>
      </CustomTabPanel>

    </Paper>
  );
}

const Item = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  ...theme.typography.body2,
  textAlign: 'center',
  color: theme.palette.text.secondary,
  height: "66%"
}));
const ItemName = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  ...theme.typography.body2,
  textAlign: 'center',
  color: theme.palette.text.secondary,
  height: "33%",
}));

